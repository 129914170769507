import { scssBreakpoint } from '../utils/general'

export default () => useBreakpoints({
    xs: parseInt(scssBreakpoint.XS),
    sm: parseInt(scssBreakpoint.SM),
    md: parseInt(scssBreakpoint.MD),
    lg: parseInt(scssBreakpoint.LG),
    xl: parseInt(scssBreakpoint.XL),
    xxl: parseInt(scssBreakpoint.XXL),
})
